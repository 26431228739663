<div class="row" >
   <div class="col-12 col-md-6 col-lg-4" *ngFor = "let product of products ">
      <div class="product" >

          <div class="product-img">
              <img src={{sharedService.ipRootImg+product?.imagePath[0].imagePath}} loading="lazy"/>
          </div>

          <div class="description">
            <div class="d-flex flex-wrap justify-content-between">
              <p>{{product ? product[translate.data.requestdData.genName] : 'N/A' }}</p>
              <p>  <star-rating [value]="5"
                [totalstars]="5"
                checkedcolor="#ed6c37"
                uncheckedcolor="rgb(224, 224, 224)"
                size="18px"
                [readonly]="true">
              </star-rating></p>
            </div>
              <h2>{{product?.salePrice}} {{translate.data.caravanCard.coin}} / {{translate.data.caravanCard.night}}</h2>
          </div>




      </div>
  </div>

</div>
