<div class="our-clients" *ngIf="userRate.length>0">
    <div class="container">
        <div class="info directionContent">
            <span>{{translate.data.clientsOpinion.title}}</span>
            <p>{{translate.data.clientsOpinion.underTitle}}</p>
        </div>
        <div class="row text-center">
          <h3 *ngIf="showClientsHeading" style="font-size: 20px;color: red;">لايوجد أراء عملاء حاليا</h3>
          <div style="width: 100%;">

          </div>
            <div class="col-12">
                <div class="first" >
                  <div class="review-container"
                      *ngFor = "let client of clients[0]?.first" (click)="handleClient(client)">
                      <img src={{sharedService.ipRootImg+client?.photo}}  />
                      <div class="review" *ngIf="choosenClient?.id == client.id">
                        <p>{{choosenClient?.review}}</p>
                        <star-rating [value]="client?.rate"
                        [totalstars]="5"
                        checkedcolor="#ed6c37"
                        uncheckedcolor="rgb(224, 224, 224)"
                        size="15px"
                        [readonly]="true">
                      </star-rating>
                      </div>
                  </div>

                </div>
            </div>
           <div class="col-12">
                <div class="second">
                    <div class="review-container"
                      *ngFor = "let client of clients[0]?.second"
                      (click)="handleClient(client)">
                    <img src={{sharedService.ipRootImg+client?.photo}} />
                    <div class="review" *ngIf="choosenClient?.id == client.id">
                      <p>{{choosenClient?.review}}</p>
                      <star-rating [value]="client?.rate"
                      [totalstars]="5"
                      checkedcolor="#ed6c37"
                      uncheckedcolor="rgb(224, 224, 224)"
                      size="15px"
                      [readonly]="true">
                    </star-rating>
                    </div>
                </div>
              </div>
            </div>
            <div class="col-12">
                <div class="third">
                    <div class="review-container"
                      *ngFor = "let client of clients[0]?.third"
                      (click)="handleClient(client)">
                      <img src={{sharedService.ipRootImg+client?.photo}}  />
                      <div class="review" *ngIf="choosenClient?.id == client.id">
                        <p>{{choosenClient?.review}}</p>
                        <star-rating [value]="client?.rate"
                        [totalstars]="5"
                        checkedcolor="#ed6c37"
                        uncheckedcolor="rgb(224, 224, 224)"
                        size="15px"
                        [readonly]="true">
                      </star-rating>

                      </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>
