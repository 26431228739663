import { Component, OnInit } from '@angular/core';
import { PagesService } from 'src/app/pages/pages.service';
import { TranslationService } from 'src/app/shared/translation.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(public translate: TranslationService,
    private pagesService: PagesService) { }

    socialLinks = {
      facebookLink: null,
      instgramLink: null,
      snapchatLink: null,
      twitterLink: null,
      youtubeLink: null
    };

    paramsData: any = JSON.stringify({}); //to add in search 

  ngOnInit(): void {
    this.pagesService.getSocialMedia().subscribe(
      (response: any) => {
        this.socialLinks = {...response.data[0]};
        console.log(this.socialLinks);
      },
      (error) => {
        console.log(error);
      }
    )
  }

}
