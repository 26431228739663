import {
  CaravanType,
  Features,
  CreateCaravan,
  Roles,
  CancelTypes,
  Cities,
  Countries,
  Conditon,
  Caravan
  
} from './caravan';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SharedService } from '../../shared/shared.service';
@Injectable({
  providedIn: 'root',
})
export class CaravanService {
/*   headers = new HttpHeaders({
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  }); */
  constructor(private http: HttpClient,
     private sharedService: SharedService) {}
  // Caravan Data ===================
/*   getCaravanDetails() {
    return this.http
      .get<Caravan[]>(environment.publicUrl + `Carvan/GetAll`, {
        headers: this.headers,
      })
      .pipe(map((res: any) => res.data));
  } */
  getCaravanId(id: number) {
   /*  return this.http
      .get<CreateCaravan>(this.sharedService,ipRoot + `Carvan/` + id, {
        headers: this.headers,
      })
      .pipe(map((res: any) => res.data)); */
      return this.http.get<CreateCaravan>(this.sharedService.ipRoot + '/Carvan/' + id).pipe(map((res: any) => res.data));
  }
  // Create New Caravan ==============
  createCaravan(CreateCaravan: CreateCaravan) {
    /* let headers = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'application/json',
    }); */
    return this.http.post(this.sharedService.ipRoot + `/Carvan`, CreateCaravan);
  }
  // Edit Caravan ====================
  editCaravan(id: number, EditCaravan: CreateCaravan) {
    return this.http.put(this.sharedService.ipRoot + `/Carvan/` + id, EditCaravan);
  }

  // Caravan Type Data ===============
  getCaravanType() {
    return this.http.get<CaravanType[]>(this.sharedService.ipRoot + '/CarvanType/GetAll').pipe(map((res: any) => res.data));
   /*  return this.http
      .get<CaravanType[]>(environment.publicUrl + `CarvanType/GetAll`, {
        headers: this.headers,
      })
      .pipe(map((res: any) => res.data)); */
  }
  // Condition Type Data =============
  getConditionType() {
    return this.http
      .get<Conditon[]>(this.sharedService.ipRoot + `/ConditionerType/GetAll`)
      .pipe(map((res: any) => res.data));
  }
  // Internet Avilabilty ===============
  getInternet() {
    return this.http
      .get<Conditon[]>(this.sharedService.ipRoot + '/Internet/GetAll')
      .pipe(map((res: any) => res.data));
  }
  // Features Data ===================
  getFeatures() {
    return this.http
      .get<Features[]>(this.sharedService.ipRoot + `/Feature/GetAll`)
      .pipe(map((res: any) => res.data));
  }
  getFeaturesId(id: number) {
    return this.http
      .get<Features[]>(
        this.sharedService.ipRoot + `/Feature/GetCarvanFeature?CarvanId=` + id)
      .pipe(map((res: any) => res.data));
  }
  // Roles Data =====================
  getRoles() {
    return this.http.get<Roles[]>(this.sharedService.ipRoot + '/Roles/GetAll').pipe(map((res: any) => res.data));
    /* return this.http
      .get<Roles[]>(this.sharedService,ipRoot + 'Roles/GetAll')
      .pipe(map((res: any) => res.data)); */
  }
  // Cities Data ====================
  getCities() {
    return this.http
      .get<Cities[]>(this.sharedService.ipRoot + `/City/GetCities`)
      .pipe(map((res: any) => res.data));
  }
  getCitiesId(id: number) {
    return this.http
      .get<Cities[]>(
        this.sharedService.ipRoot + `/City/CityByContry?countryId=` + id
      )
      .pipe(map((res: any) => res.data));
  }
  // Countries Data ====================
  getCountries() {
    return this.http
      .get<Countries[]>(this.sharedService.ipRoot + `/Country/GetAll`)
      .pipe(map((res: any) => res.data));
  }
  // Cancle Type ====================
  getCancleTypes() {
    return this.http
      .get<CancelTypes[]>(this.sharedService.ipRoot + `/CancelType/GetAll`)
      .pipe(map((res: any) => res.data));
  }
  // Delete Caravan ==================
  deleteCaravan(id: number) {
    return this.http
      .delete(this.sharedService.ipRoot + `/Carvan/` + id)
      .pipe(map((res: any) => res.data));
  }
 
}
