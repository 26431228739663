<div class="new-caravan directionContent">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3">
                <div class="new-nav">
                    <!-- <a routerLink="/pages/caravans"><i class="fas fa-arrow-right"></i> الرجوع الى القائمة الكارفانات</a> -->
                    <div class="card new-card" style="width: 100%;">
                        <div class="card-header">
                            بيانات الكارفان
                        </div>
                        <div class="card-body">
                            <div class="d-flex align-items-start">
                                <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist"
                                    aria-orientation="vertical">
                                    <a class="nav-link active" id="v-pills-details-tab" data-bs-toggle="pill"
                                        data-toggle="tab" href="#details" role="tab" aria-controls="v-pills-details"
                                        aria-selected="true"><span>1</span>مواصفات الكارفان </a>
                                    <a class="nav-link" id="v-pills-address-tab" data-bs-toggle="pill" data-toggle="tab"
                                        href="#address" role="tab" aria-controls="v-pills-address"
                                        aria-selected="false"><span>2</span>العنوان </a>
                                    <a class="nav-link" id="v-pills-prices-tab" data-bs-toggle="pill" href="#prices"
                                        data-toggle="tab" role="tab" aria-controls="v-pills-prices"
                                        aria-selected="false">
                                        <span>3</span>القواعد الاساسية / السعر</a>
                                    <a class="nav-link" id="v-pills-images-tab" data-bs-toggle="pill" href="#images"
                                        data-toggle="tab" role="tab" aria-controls="v-pills-images"
                                        aria-selected="false"><span>4</span>صور الكارفان </a>
                                    <a class="nav-link" id="v-pills-papers-tab" data-bs-toggle="pill" href="#papers"
                                        data-toggle="tab" role="tab" aria-controls="v-pills-papers"
                                        aria-selected="false"><span>5</span>أوراق رسمية </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-9">
                <div class="nav-content">
                    <form #detailsForm="ngForm" (ngSubmit)="onSubmit(detailsForm.value)" novalidate>
                        <div class="tab-content" id="v-pills-tabContent">
                            <div class="tab-pane fade show active" id="details" role="tabpanel"
                                aria-labelledby="v-pills-details-tab">
                                <div class="card details-card" style="width: 100%;">
                                    <div class="card-header">
                                        مواصفات الكارفان
                                    </div>
                                    <div class="card-body">
                                        <div class="details-form">
                                            <div class="row mb-3">
                                                <div class="col-md-4">
                                                    <label for="nameInputAR" class="form-label">اسم
                                                        الكارفان
                                                        <span class="muted small">عربى</span></label>
                                                    <input type="text" class="form-control" id="nameInputAR"
                                                        name="nameAr" [(ngModel)]="EditCaravan.nameAr" #name="ngModel"
                                                        required>
                                                    <span *ngIf="!name.valid && name.touched" class="help">
                                                        من فضلك أدخل اسم الكارافان
                                                    </span>
                                                </div>
                                                <div class="col-md-4">
                                                    <label for="nameInputEN" class="form-label">اسم
                                                        الكارفان
                                                        <span class="muted small">English</span></label>
                                                    <input type="text" class="form-control" id="nameInputEN"
                                                        name="nameEn" [(ngModel)]="EditCaravan.nameEn" #nameEn="ngModel"
                                                        required>
                                                    <span *ngIf="!nameEn.valid && nameEn.touched" class="help">
                                                        من فضلك أدخل اسم الكارافان
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-md-4">
                                                    <label for="descriptionAR" class="form-label">الوصف
                                                        <span class="muted small">عربى</span>
                                                    </label>
                                                    <textarea class="form-control" id="descriptionAR" rows="3"
                                                        name="descriptionAr" [(ngModel)]="EditCaravan.descriptionAr"
                                                        required #desc="ngModel"></textarea>
                                                    <span *ngIf="!desc.valid && desc.touched" class="help">
                                                        من فضلك أدخل الوصف
                                                    </span>
                                                </div>
                                                <div class="col-md-4">
                                                    <label for="descriptionEN" class="form-label">
                                                        الوصف
                                                        <span class="muted small">English</span></label>
                                                    <textarea class="form-control" id="descriptionEN" rows="3"
                                                        name="descriptionEn" [(ngModel)]="EditCaravan.descriptionEn"
                                                        required #descEn="ngModel"></textarea>
                                                    <span *ngIf="!descEn.valid && descEn.touched" class="help">
                                                        من فضلك أدخل الوصف
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-md-4">
                                                    <label for="modelInput" class="form-label">الموديل</label>
                                                    <input type="text" class="form-control" id="modelInput"
                                                        list="modelOptions" name="modal" [(ngModel)]="EditCaravan.modal"
                                                        required #modal="ngModel">
                                                    <datalist id="modelOptions">
                                                        <option value="2017">
                                                        <option value="2018">
                                                        <option value="2019">
                                                        <option value="2020">
                                                        <option value="2021">
                                                    </datalist>
                                                    <span *ngIf="!modal.valid && modal.touched" class="help">
                                                        من فضلك أدخل الموديل
                                                    </span>
                                                </div>
                                                <div class="col-md-4">
                                                    <label for="yearInput" class="form-label">سنة التصنيع</label>
                                                    <input type="number" class="form-control" list="yearOptions"
                                                        id="yearInput" name="yearOfManufacture" 
                                                        required #yearOfManufacture="ngModel"
                                                        [(ngModel)]="EditCaravan.yearOfManufacture">
                                                    <datalist id="yearOptions">
                                                        <option value="2017">
                                                        <option value="2018">
                                                        <option value="2019">
                                                        <option value="2020">
                                                        <option value="2021">
                                                    </datalist>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-md-4">
                                                    <label for="personInput" class="form-label">عدد الكراسى</label>
                                                    <input type="number" class="form-control" id="personInput"
                                                        name="seatNo" [(ngModel)]="EditCaravan.seatNo" required #seatNo="ngModel">
                                                </div>
                                                <div class="col-md-4">
                                                    <label for="bedsInput" class="form-label">عدد السراير</label>
                                                    <input type="number" class="form-control" id="bedsInput"
                                                        name="bedsNo" [(ngModel)]="EditCaravan.bedsNo" required #bedsNo="ngModel">
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-md-4">
                                                    <label for="lInput" class="form-label">عرض الكارفان</label>
                                                    <input type="number" class="form-control" id="lInput" name="width"
                                                        [(ngModel)]="EditCaravan.width" required #width="ngModel">
                                                </div>
                                                <div class="col-md-4">
                                                    <label for="tallInput" class="form-label">طول الكارفان</label>
                                                    <input type="number" class="form-control" id="tallInput"
                                                        name="length" [(ngModel)]="EditCaravan.length" required #length="ngModel">
                                                </div>
                                            </div>
                                            <div class="c-type">
                                                <div class="row">
                                                    <div class="col-md-11 o-scroll">
                                                        <h4>نوع الكارفان</h4>
                                                        <ul class="type-list">
                                                            <li class="caravan-model" *ngFor="let t of CaravanType">
                                                                <input type="radio" name="caravanTypeId" id="tRadio"
                                                                    [value]=t.id [(ngModel)]="EditCaravan.caravanTypeId"
                                                                    required #type="ngModel" required>
                                                                <img src="assets/Images/Layer2-3.png" alt="">
                                                                <p for="tRadio">{{t.nameEn}}</p>
                                                                <span *ngIf="!type.valid && type.touched" class="help">
                                                                    من فضلك أدخل نوع الكارافان
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="c-features">
                                                <h2>المميزات</h2>
                                                <div *ngIf="isAddMode">
                                                    <div *ngFor="let s of Feature">
                                                        <h4>{{s.nameAr}}</h4>
                                                        <div class="form-check form-check-inline mb-2"
                                                            *ngFor="let c of s.featureItems">
                                                            <input class="form-check-input" type="checkbox"
                                                                (change)="fetchCheckedIDs(c)" [(ngModel)]="c.isChecked"
                                                                name="features" [value]=c.id>
                                                            <label class="form-check-label">
                                                                {{c.nameAr}}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="!isAddMode">
                                                    <div *ngFor="let s of FeaturesData">
                                                        <h4>{{s.nameAr}}</h4>
                                                        <div class="form-check form-check-inline mb-2"
                                                            *ngFor="let c of s.featureItems">
                                                            <input class="form-check-input" type="checkbox"
                                                                (change)="fetchCheckedIDs(c)" [checked]="c.isChecked"
                                                                name="features" [value]=c.id>
                                                            <label class="form-check-label">
                                                                {{c.nameAr}}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- ============== -->
                                                <h4>نوع المكيف</h4>
                                                <div class="form-check form-check-inline mb-3"
                                                    *ngFor="let t of Conditon">
                                                    <input class="form-check-input t-radio" type="radio"
                                                        name="conditionerTypeId"
                                                        [(ngModel)]="EditCaravan.conditionerTypeId" id="condRadio"
                                                        [value]=t.id>
                                                    <label class="form-check-label" for="condRadio">{{t.nameAr}}</label>
                                                </div>
                                                <!-- ============== -->
                                                <h4>الأنترنت </h4>
                                                <div class="form-check form-check-inline mb-3"
                                                    *ngFor="let t of Internet">
                                                    <input class="form-check-input t-radio" type="radio"
                                                        name="internetAvailabilityId"
                                                        [(ngModel)]="EditCaravan.internetAvailabilityId" id="condRadio"
                                                        [value]=t.id>
                                                    <label class="form-check-label" for="condRadio">{{t.nameAr}}</label>
                                                </div>
                                            </div>
                                            <div class="row text-center">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <button type="button" class="btn btn-next"
                                                         [disabled]="(!name.valid
                                                                    || !modal.valid
                                                                    || !seatNo.valid
                                                                    || !bedsNo.valid
                                                                    || !length.valid
                                                                    || !desc.valid
                                                                    || !descEn.valid
                                                                    || !EditCaravan.caravanTypeId
                                                                    || !EditCaravan.conditionerTypeId
                                                                    || !EditCaravan.internetAvailabilityId
                                                                    || checkedIDs.length == 0
                                                                    || !width.valid)" (click)="toNext()"
                                                            >التالى</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- ================================= -->
                            <div class="tab-pane fade" id="address" role="tabpanel"
                                aria-labelledby="v-pills-address-tab">
                                <div class="card address-card">
                                    <div class="card-header">
                                        العنوان
                                    </div>
                                    <div class="card-body">
                                        <div class="address-form">
                                            <div class="row mb-3">
                                                <div class="col-md-4">
                                                    <label for="countryInput" class="form-label">الدولة</label>
                                                    <select class="custom-select" (change)="getcountryId($event)"
                                                        
                                                        >
                                                        <option selected></option>
                                                        <option *ngFor="let c of Countries" [value]="c.id">
                                                            {{c.nameAr}}</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-4">
                                                    <label for="cityInput" class="form-label"> المدينة</label>
                                                    <select class="custom-select" name="cityId" ngModel
                                                        [(ngModel)]="EditCaravan.cityId"
                                                         required #city="ngModel">
                                                        <option selected>
                                                        </option>
                                                        <option *ngFor="let c of Cities" [ngValue]="c.id">
                                                            {{c.nameAr}}</option>
                                                    </select>
                                                    <h4 class="cityname" *ngIf="!isAddMode">{{EditCaravan?.cityName}}</h4>
                                                    <span *ngIf="!city.valid && city.touched" class="help">
                                                        من فضلك أدخل المدينه
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-md-4">
                                                    <label for="addressInput" class="form-label">العنوان</label>
                                                    <input type="text" class="form-control" name="address"
                                                        [(ngModel)]="EditCaravan.address" id="addressInput" required
                                                        #address="ngModel">
                                                    <span *ngIf="!address.valid && address.touched" class="help">
                                                        من فضلك أدخل العنوان
                                                    </span>
                                                </div>
                                            </div>
                                            <!-- ============== -->
                                            <agm-map [latitude]="lat" [longitude]="lng" [(latitude)]="EditCaravan.lat"
                                                [(longitude)]="EditCaravan.long" [zoom]="zoom">
                                                <agm-marker [(latitude)]="EditCaravan.lat" [latitude]="lat"
                                                    (dragEnd)="markerDragEnd($event)"
                                                    [(longitude)]="EditCaravan.long"
                                                    [longitude]="lng" (markerClick)="onChooseLocation($event)">
                                                </agm-marker>
                                            </agm-map>
                                            <!-- ================== -->
                                            <div class="row text-center">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <button type="button" class="btn btn-next" (click)="toNext()"
                                                            [disabled]="(!city.valid || !address.valid)">التالى</button>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <button type="button" class="btn btn-prev"
                                                        (click)="toPrev()">السابق</button>
                                                </div>
                                                <div class="offset-md-4"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- ================================ -->
                            <div class="tab-pane fade " id="prices" role="tabpanel"
                                aria-labelledby="v-pills-prices-tab">
                                <div class="card prices-card">
                                    <div class="card-header">
                                        القواعد الاساسية / السعر
                                    </div>
                                    <div class="card-body">
                                        <div class="prices-form">
                                            <div class="p-role">
                                                <h3>القواعد الأساسية</h3>
                                                <div *ngIf="isAddMode">
                                                    <div *ngFor="let s of Roles">
                                                        <h4>{{s.nameAr}}</h4>
                                                        <div class="form-check form-check-inline mb-2"
                                                            *ngFor="let r of s.roleItems">
                                                            <input class="form-check-input" type="checkbox"
                                                                (change)="roleCheckedIDs(r)" [(ngModel)]="r.isChecked"
                                                                name="roles" [value]=r.id>
                                                            <label class="form-check-label">
                                                                {{r.nameAr}}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="!isAddMode">
                                                    <div *ngFor="let s of RolesData">
                                                        <h4>{{s.nameAr}}</h4>
                                                        <div class="form-check form-check-inline mb-2"
                                                            *ngFor="let r of s.roleItems">
                                                            <input class="form-check-input" type="checkbox"
                                                                (change)="roleCheckedIDs(r)" [checked]="r.isChecked"
                                                                name="roles" [value]=r.id>
                                                            <label class="form-check-label">
                                                                {{r.nameAr}}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br>
                                                <h3>نظام الغاء الحجز</h3>
                                                <div class="row mb-3">
                                                    <div class="form-check form-check-inline p-check"
                                                        *ngFor="let c of CancelType">
                                                        <input class="form-check-input" type="radio" id="inlineRadio1"
                                                            name="cancelTypeId" [value]=c.id
                                                            [(ngModel)]="EditCaravan.cancelTypeId">
                                                        <label class="form-check-label"
                                                            for="inlineRadio1">{{c.nameAr}}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-md-4">
                                                    <label for="priceInput" class="form-label"> سعر الليلة</label>
                                                    <input type="number" class="form-control" id="priceInput"
                                                        name="pricePerDay" [(ngModel)]="EditCaravan.pricePerDay"
                                                        #price="ngModel" required>
                                                    <span *ngIf="!price.valid && price.touched" class="help">
                                                        من فضلك أدخل السعر
                                                    </span>
                                                </div>
                                                <div class="col-md-4">
                                                    <label for="insureInput" class="form-label"> التأمين </label>
                                                    <input type="number" class="form-control" id="insureInput"
                                                        name="taxPerDay" [(ngModel)]="EditCaravan.taxPerDay"
                                                        #tax="ngModel" required>
                                                    <span *ngIf="!tax.valid && tax.touched" class="help">
                                                        من فضلك أدخل التأمين
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="row text-center">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <button type="button" class="btn btn-next" (click)="toNext()"
                                                            [disabled]="(!price.valid || !tax.valid)">التالى</button>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <button type="button" class="btn btn-prev"
                                                        (click)="toPrev()">السابق</button>
                                                </div>
                                                <div class="offset-md-4"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- =================================== -->
                            <div class="tab-pane fade" id="images" role="tabpanel" aria-labelledby="v-pills-images-tab">
                                <div class="card images-card">
                                    <div class="card-header">
                                        صور الكارفان
                                    </div>
                                    <div class="card-body">
                                        <h2>أضف صورة</h2>
                                        <div class="row">
                                            <div class="col-md-11 o-scroll">
                                                <ul class="images-list">
                                                    <li *ngFor="let i of imageArr; let in = index">
                                                        <div class="overllay"></div>
                                                        <img [src]="i" alt="">
                                                        <div class="delete-img">
                                                            <img class="delete" (click)="removeSelectedFile(in)"
                                                                src="../../../../assets/delete.svg" alt="">
                                                        </div>
                                                    </li>
                                                    <li *ngFor="let i of EditCaravan?.images">
                                                        <div class="overllay"></div>
                                                        <img *ngIf="!isAddMode" [src]="sharedService.ipRootImg +i.imagePath" alt="">
                                                        <div class="delete-img">
                                                            <img class="delete" (click)="removeSelectedFile(i)"
                                                                src="../../../../assets/delete.svg" alt="">
                                                        </div>
                                                    </li>
                                                    <li class="add">
                                                        <label for="file-input">
                                                            <img src="../../../../assets/add-img.svg" alt="">
                                                        </label>
                                                        <input type="file" name="images" id="file-input"
                                                            accept="image/*" (change)="handleInputChange($event)"
                                                            ngModel />
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="offset-md-2"></div>
                                        </div>
                                        <div class="row text-center">
                                            <div class="col-md-4">
                                                <button class="btn btn-next" type="button"
                                                    (click)="toNext()" [disabled]="imageArr.length == 0">التالى</button>
                                            </div>
                                            <div class="col-md-4">
                                                <button type="button" class="btn btn-prev"
                                                    (click)="toPrev()">السابق</button>
                                            </div>
                                            <div class="offset-md-4"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- =================================== -->
                            <div class="tab-pane fade" id="papers" role="tabpanel" aria-labelledby="v-pills-papers-tab">
                                <div class="card papers-card">
                                    <div class="card-header">
                                        أوراق رسمية
                                    </div>
                                    <div class="card-body">
                                        <div class="form-row">
                                            <div class="form-group col-md-4">
                                                <label for="licence">رقم رخصة السيارة</label>
                                                <input type="text" class="form-control" id="licence"
                                                    name="licenseNumber" [(ngModel)]="EditCaravan.licenseNumber">
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-md-8 form-group">
                                                <label for="">أرفاق صورة رخصة السيارة</label>
                                                <div class="upload">
                                                    <div class="text-center">
                                                        <img *ngIf="lincenceImages == null"
                                                            src=" ../../../../../../assets/upload.svg" alt="">
                                                        <ul class="paper-list">
                                                            <li *ngFor="let i of lincenceImages; let in = index">
                                                                <div class="overllay"></div>
                                                                <img [src]="i" alt="">
                                                                <div class="delete-img">
                                                                    <img class="delete" (click)="removeLicenseFile(in)"
                                                                        src="../../../../assets/delete.svg" alt="">
                                                                </div>
                                                            </li>
                                                            <li *ngFor="let i of EditCaravan?.licenseImages">
                                                                <div class="overllay"></div>
                                                                <img *ngIf="!isAddMode" [src]="sharedService.ipRootImg +i.imagePath" alt="">
                                                                <div class="delete-img">
                                                                    <img class="delete" (click)="removeLicenseFile(i)"
                                                                        src="../../../../assets/delete.svg" alt="">
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <h5>IMG Up to 50 MG</h5>
                                                    <h5>قم بأرفاق صورة</h5>
                                                    <label for="license">
                                                        تحميل ملف
                                                    </label>
                                                    <input type="file" class="form-control" name="licenseImages"
                                                        id="license" accept="image/*"
                                                        (change)="handleLicenseChange($event)" ngModel hidden>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-md-4">
                                                <label for="pId">رقم الهوية الشخصية</label>
                                                <input type="text" class="form-control" id="pId" name="ssn"
                                                    [(ngModel)]="EditCaravan.ssn">
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-md-8 form-group">
                                                <label for="">أرفاق صورة الهوية الشخصية</label>
                                                <div class="upload">
                                                    <div class="text-center">
                                                        <!-- <img src="assets/Images/upload.svg"
                                                            alt=""> -->
                                                        <ul class="paper-list">
                                                            <li *ngFor="let i of ssnImages; let in = index">
                                                                <div class="overllay"></div>
                                                                <img [src]="i" alt="">
                                                                <div class="delete-img">
                                                                    <img class="delete" (click)="removeSNNFile(i)"
                                                                        src="../../../../assets/delete.svg" alt="">
                                                                </div>
                                                            </li>
                                                            <li *ngFor="let i of EditCaravan?.ssnImages">
                                                                <div class="overllay"></div>
                                                                <img *ngIf="!isAddMode" [src]="sharedService.ipRootImg +i.imagePath" alt="">
                                                                <div class="delete-img">
                                                                    <img class="delete" (click)="removeSNNFile(i)"
                                                                        src="../../../../assets/delete.svg" alt="">
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <h5>IMG Up to 50 MG</h5>
                                                    <h5>قم بأرفاق صورة</h5>
                                                    <label for="snn">
                                                        تحميل ملف
                                                    </label>
                                                    <input type="file" class="form-control" name="ssnImages" id="snn"
                                                        accept="image/*" (change)="handleSSnChange($event)" ngModel
                                                        hidden>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row text-center">
                                            <div class="col-md-4">
                                                <button class="btn btn-next" type="submit" [disabled]="lincenceImages.length == 0 || ssnImages.length == 0">انهاء</button>
                                                <button hidden data-toggle="modal" data-target="#doneModalCenter"
                                                    #doneModal type="button"></button>
                                            </div>
                                            <div class="col-md-4">
                                                <button type="button" class="btn btn-prev"
                                                    (click)="toPrev()">السابق</button>
                                            </div>
                                            <div class="offset-md-4"></div>
                                        </div>
                                        <!-- MODEL -->
                                        <div class="modal fade done-popup" id="doneModalCenter" tabindex="-1"
                                            role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                            <div class="modal-dialog modal-dialog-centered" role="document">
                                                <div class="modal-content">
                                                    <button type="button" class="close" data-dismiss="modal"
                                                        aria-label="Close">
                                                        <span aria-hidden="true">
                                                            <img src="../../../../assets/close.png" alt="">
                                                        </span>
                                                    </button>
                                                    <div class="model-img">
                                                        <img src="../../../../assets/success.png" alt="">
                                                    </div>
                                                    <div class="modal-body text-center">
                                                        <h2>تم المشاركة بنجاح</h2>
                                                        <p>مساعدة بالولايات نفس ما. اسبوعين المجتمع بالمحور دول من,
                                                            عن
                                                            دار وبدأت الصفحات. أسيا وبالرغم ما انه, كل إعادة
                                                            وبلجيكا،
                                                            الأوربيين ذات. قبل ان وبعض قتيل.</p>
                                                        <div class="row">
                                                            <div class="col-6">
                                                                <button type="button" class="btn btn-c"
                                                                    routerLink="/pages/caravans" data-dismiss="modal"
                                                                    aria-label="Close">قائمة
                                                                    الكارفانات</button>
                                                            </div>
                                                            <div class="col-6">
                                                                <button type="button" class="btn btn-h"
                                                                    routerLink="/pages/home" data-dismiss="modal"
                                                                    aria-label="Close">الرئيسية</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- ================== -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#474fa0" type="ball-circus" [fullScreen]="true"
    name="loader">
    <p style="color: white">
        برجاء الانتظار ... جارى اتمام العملية </p>
</ngx-spinner>