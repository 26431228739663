<div class="row pagnation" >
    <h4 class="nodata" *ngIf="karavans?.length == 0">لا يوجد داتا لعرضها !</h4>
     <div class="col-12 col-md-6 col-lg-4" *ngFor = "let karavan of karavans ">
        <div class="karavan" >
            <div class="btns-div">
                <button (click)="handelHeart(karavan)" class="btttn heartBtn"  *ngIf="!karavan.isFavorite">
                </button>
                <button (click)="handelHeart(karavan)" class="btttn disheartBtn" *ngIf="karavan.isFavorite">
                </button>
                <button class="btttn shareBtn" (click)="copyLink()" ngxClipboard [cbContent]="navUrl">
                </button>
            </div>

            <div class="karavan-img">
                <img *ngIf="karavan?.image" src={{sharedService.ipRootImg+karavan?.image[0]}} />
                <img *ngIf="!karavan?.image" src={{sharedService.ipRootImg+karavan?.image}} />

            </div>

            <div class="details">
                <p class="year">
                    {{karavan?.yearOfManufacture}}
                    <span>
                        <star-rating [value]="karavan?.rate"
                                    [totalstars]="5"
                                    checkedcolor="#ed6c37"
                                    uncheckedcolor="rgb(224, 224, 224)"
                                    size="18px"
                                    [readonly]="true">
                        </star-rating>
                    </span>
                </p>
            </div>

            <div class="description">
                <p>{{karavan ? karavan[translate.data.requestdData.genName] : 'N/A'}}</p>
                <h2>{{karavan?.pricePerDay}}  {{translate.data.caravanCard.coin}}/{{translate.data.caravanCard.night}}</h2>
            </div>

            <div class="icons-rate">
                <div class="sitiing">
                    <img src="../../../../assets/Content/office-chair/office-chair.png" />
                    <span>{{karavan?.seatNo}}</span>
                </div>
                <div class="sitiing">
                    <img src="../../../../assets/Content/bed/bed.png" style="height: 13px;"/>
                    <span>{{karavan?.bedsNo}}</span>
                </div>
            </div>
            <button (click)="onhandle(karavan.id)">{{translate.data.result.bookNow}}</button>
        </div>
    </div>
    <p-paginator *ngIf="karavans?.length != 0" [rows]="6" [totalRecords]= "karavansLength" (onPageChange)="paginate($event)"></p-paginator>
</div>

