import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/shared/translation.service';
import { PagesService } from '../../pages.service';
import { SharedService } from 'src/app/shared/shared.service';


@Component({
  selector: 'app-our-clients',
  templateUrl: './our-clients.component.html',
  styleUrls: ['./our-clients.component.scss']
})
export class OurClientsComponent implements OnInit {

  data:any;
  inf:any;
  ip :any ;
  active:string;

  //clients data array
  clients: any[] = [{
    'first': [],
    'second': [],
    'third': []
  }];

  showClientsHeading: boolean = false;


  choosenClient: any;
  clientRate:any = [];
  userRate:any = [];

  constructor(public translate: TranslationService,
     private pagesService: PagesService,
     public sharedService: SharedService ) { }

  ngOnInit(): void {

    //get clients
    this.pagesService.getHighRatedReviews().subscribe(
      (response:any) => {
        console.log('our clienttttsssss =====> ', response);
        if(response.data){
          this.userRate = response.data;}
        // this.getData(response.data);
        // if(response.data){
        //   response.data.map((item: any) => (
        //     item.review.length > 50 ? item.review = item.review.substring(1,50) + '....' : item.review
        //   ))
        // }
      },
      (error:any) => {
        console.log(error);
      }
    )
    this.getClientRate();
    console.log("this.userRate",this.userRate);

  }

  getClientRate(){
    this.pagesService.getHighRatedReviewsClient().subscribe(
      (response:any) => {
        this.clientRate = response.data;
        if(this.clientRate){
          this.userRate = [...this.userRate,...this.clientRate];
        }
        console.log('our clienttttsssss =====> ', response);
        this.getData(this.userRate);
        if(this.userRate){
          this.userRate.map((item: any) => (
            item.review.length > 50 ? item.review = item.review.substring(1,50) + '....' : item.review
          ))
        }
      },
      (error:any) => {
        console.log(error);
      }
    )
  }

  //to reform data
  getData(data: any) {
    if(!data) {
      this.showClientsHeading = true;
      return;
    }
    data.forEach((client: any, index: number) => {
      if(index < 2) {
        this.clients[0].first.push(client);
      }
      if(index >=2 && index < 5) {
        this.clients[0].second.push(client);
      }
      if(index >=5 && index < 7) {
        this.clients[0].third.push(client);
      }
    });
  }

  //handle click on client
  handleClient(client: any) {
    console.log(client);
    this.choosenClient = client;
    setTimeout(() => {
      this.choosenClient = null;
    }, 2000);
  };

}
