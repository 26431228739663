<div class="filtter">
    <div class="container">
        <div class="content directionContent">
            <div class="row">
                <div class="col-6 col-md-2 relativeC margin">
                    <img src="../../../../assets/Content/location-pin/location-pin.png" class="d-md-none d-lg-block"/>
                    <p-dropdown [options]="countries"
                        [(ngModel)]="searchOject.CountryId"
                        (onChange)="onChangeCountry()"
                        optionLabel="nameAr"
                        [showClear]="true"
                        styleClass="countrySelect"
                        [placeholder]="translate.is_arabic ? 'الدوله' : 'Country'">
                        <ng-template pTemplate="selectedItem">
                            <div class="country-item country-item-value"  *ngIf="searchOject.CountryId">
                                <div>{{searchOject.CountryId[translate.data.requestdData.genName]}}</div>
                            </div>
                        </ng-template>
                        <ng-template let-country pTemplate="item">
                            <div class="country-item">
                                <div>{{country[translate.data.requestdData.genName]}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                    <!-- <img class="arrow-icon" src="../../../../assets/Path 6.png"/> -->
                </div>
                <div class="col-6 col-md-2 relativeC margin">
                    <img src="../../../../assets/Content/location-pin/location-pin.png" class="d-md-none d-lg-block"/>
                    <i class="bi bi-geo-alt-fill"></i>
                    <p-dropdown [options]="cities"
                        [(ngModel)]="searchOject.cityId"
                        optionLabel="nameAr"
                        [showClear]="true"
                        styleClass="countrySelect"
                        [placeholder]="translate.is_arabic ? 'المدينه' : 'City'">
                        <ng-template pTemplate="selectedItem">
                            <div class="city-item city-item-value"  *ngIf="searchOject.cityId">
                                <div>{{searchOject.cityId[translate.data.requestdData.genName]}}</div>
                            </div>
                        </ng-template>
                        <ng-template let-city pTemplate="item">
                            <div class="city-item">
                                <div>{{city[translate.data.requestdData.genName]}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                    <!-- <img class="arrow-icon" src="../../../../assets/Path 6.png"/> -->
                </div>
                <div class="col-6 col-md-2 relativeC margin">
                    <img src="../../../../assets/Content/calendar/calendar.png" class="d-md-none d-lg-block"/>
                    <p-calendar [(ngModel)]="searchOject.from"
                                [placeholder]="translate.is_arabic ? 'تاريخ المغادره' : 'leave Date'"
                                dateFormat="dd.mm.yy"
                                (onSelect)="changeLeftDate()"
                                styleClass="calenderStyle"
                                [minDate]="todayDate"
                                >
                    </p-calendar>
                </div>

                <div class="col-6 col-md-2 relativeC margin">
                    <img src="../../../../assets/Content/calendar/calendar.png" class="d-md-none d-lg-block"/>
                    <p-calendar [(ngModel)]="searchOject.to"
                                dateFormat="dd.mm.yy"
                                [placeholder]="translate.is_arabic ? 'تاريخ العوده' : 'Back Date'"
                                styleClass="calenderStyle"
                                [minDate]="minDateValue"></p-calendar>
                </div>

                <div class="col-6 col-md-2 relativeC spec margin">
                    <label for="select"><i class="bi bi-geo-alt-fill"></i></label>
                    <img src="../../../../assets/Content/office-chair/office-chair.png" class="d-md-none d-lg-block"/>
                     <p-dropdown [options]="sites"
                        [(ngModel)]="searchOject.seatNo"
                        optionLabel="name"
                        [showClear]="true"
                        styleClass="countrySelect"
                        [placeholder]="translate.is_arabic ? 'مقعد' : 'Seat'">
                        <ng-template pTemplate="selectedItem">
                            <div class="desk-item desk-item-value"  *ngIf="searchOject.seatNo">
                                <div>{{searchOject.seatNo.name}}</div>
                            </div>
                        </ng-template>
                        <ng-template let-site pTemplate="item">
                            <div class="desk-item">
                                <div>{{site.name}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                    <!-- <img class="arrow-icon" src="../../../../assets/Path 6.png"/> -->
                </div>

                <div class="col-6 col-md-2 relativeC spec margin">
                    <label for="select"><i class="bi bi-geo-alt-fill"></i></label>
                    <img src="../../../../assets/Content/bed/bed@2x.png" style="height: 13px;" class="d-md-none d-lg-block"/>
                   <p-dropdown [options]="beds"
                            [(ngModel)]="searchOject.bedsNo"
                            optionLabel="name"
                            [showClear]="true"
                            styleClass="countrySelect"
                            [placeholder]="translate.is_arabic ? 'سرير' : 'Beds'">
                            <ng-template pTemplate="selectedItem">
                                <div class="country-item country-item-value"  *ngIf="searchOject.bedsNo">
                                    <div>{{searchOject.bedsNo.name}}</div>
                                </div>
                            </ng-template>
                            <ng-template let-bed pTemplate="item">
                                <div class="country-item">
                                    <div>{{bed.name}}</div>
                                </div>
                            </ng-template>
                    </p-dropdown>
                    <!-- <img class="arrow-icon" src="../../../../assets/Path 6.png"/> -->
                </div>

                <div class="col-6 col-md-1 relativeC">
                    <button type="button" class="btn btn-primary" (click)="onSearchHandel()">{{translate.data.HomePage.search}}</button>
                </div>

            </div>
        </div>
    </div>
</div>
