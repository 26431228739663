import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagesRoutingModule } from './pages-routing.module';
import { PagesComponent } from './pages.component';
import { SharedModule } from '../shared/shared.module';
import { PagesSharedModule } from './pagesShared.module';
import { PlaceDetailComponent } from './components/place-detail/place-detail.component';
// import {PaginatorModule} from 'primeng/paginator';

@NgModule({
  declarations: [PagesComponent, PlaceDetailComponent],
  imports: [
    CommonModule,
    PagesRoutingModule,
    SharedModule,
    PagesSharedModule,
    // PaginatorModule
  ],
  providers: [
  ],
  exports: [
  ]
})
export class PagesModule { }
