import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from '../components/page-not-found/page-not-found.component';
import { NewCaravanComponent } from './components/new-caravan/new-caravan.component';
import { PlaceDetailComponent } from './components/place-detail/place-detail.component';
import { PagesComponent } from './pages.component';

const routes: Routes = [
  {path: '', component: PagesComponent, children: [
    {path: '', redirectTo: 'home'},
    {path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule)},
    {path: 'contact', loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule)},
    {path: 'about', loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule)},
    {path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)},
    {path: 'policy', loadChildren: () => import('./policy/policy.module').then(m => m.PolicyModule)},
    {path: 'privacyPolicy', loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)},
    {path: 'questions', loadChildren: () => import('./questions/questions.module').then(m => m.QuestionsModule)},
    {path: 'payMethod/:id', loadChildren: () => import('./pay-method/pay-method.module').then(m => m.PayMethodModule)},
    {path: 'caravanDetails/:id', loadChildren: () => import('./caravan-details/caravan-details.module').then(m => m.CaravanDetailsModule)},
    {path: 'search', loadChildren: () => import('./search/search.module').then(m => m.SearchModule)},
    {path: 'reserve/:id', loadChildren: () => import('./reserve/reserve.module').then(m => m.ReserveModule)},
    {path: 'shareYourCar', loadChildren: () => import('./share-your-car/share-your-car.module').then(m => m.ShareYourCarModule)},
    {path: 'suggested-palces', loadChildren:() => import('./suggested-places/suggested-places.module').then(m =>m.SuggestedPlacesModule)},
    {path: 'products/:productId/:reserveId'
        , loadChildren:() => import('./product-details/product-details.module').then(m =>m.ProductDetailsModule)},
    {path: 'store/:reserveId', loadChildren:() => import('./store/store.module').then(m =>m.StoreModule)},
    {path: 'detail-place/:placeId', component: PlaceDetailComponent},
    {path: 'share', component: NewCaravanComponent},
    {path: 'page-not-found', component: PageNotFoundComponent},
    {path: '**', redirectTo: 'page-not-found'}
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
