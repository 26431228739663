import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication/Services/authentication.service';
import { SharedService } from 'src/app/shared/shared.service';
import { TranslationService } from '../../shared/translation.service';
import { SocialAuthService } from 'angularx-social-login';

declare const window: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(public translate: TranslationService,
     private router: Router,
     public authService: AuthenticationService,
     private socialAuthService: SocialAuthService,
     private sharedService: SharedService,
     private route: ActivatedRoute,
     ) {
  }

  menuList: any [];
  items = ['profile', 'booking', 'logout'];
  item: string;
  selectedMenu: string = 'booking';
  showMobileNav: boolean = false; //to show / hide mobile nav

  paramsData: any = JSON.stringify({});


  //menu profile list
  menuProfile: any[];
  selectedOption: any;
  language: any;
  pathLang: string;
  langIms = [
    {photo: '../../../assets/1024px-Flag_of_Saudi_Arabia.svg.png', lang: 'ar'},
    {photo: '../../../assets/1024px-Flag_of_the_United_Kingdom.svg.png', lang: 'en'}
  ];

  ngOnInit(): void {
    this.language = localStorage.getItem('languageApp');

    if(!this.language) {
      this.language = 'ar';
      this.pathLang = this.langIms[0].photo;
    } else {
        if(this.language == 'ar') {
          this.pathLang = this.langIms[0].photo;
        } else {
          this.pathLang = this.langIms[1].photo;
        }
    };

    this.sharedService.useData = this.authService.getUser();
    this.sharedService.headerOptionValue.subscribe(
      (option: any) => {
        this.selectedOption = option;
      }
    );


    this.menuProfile = [
      { id: 1, name: this.sharedService.useData.fullName},
      {id: 2, name: 'الصفحه الشخصيه', nameEn: 'profile'},
      {id: 3, name: 'حجوزاتي',  nameEn: 'myBooking'},
      {id: 4, name: 'تسجيل الخروج',  nameEn: 'logOut'}
    ]

    this.sharedService.headerOptionValue.next(this.menuProfile[0]);

  }


  //logOut
  onChange() {
    console.log(this.selectedOption);
    switch(this.selectedOption.nameEn) {
      case 'profile' :
        console.log('profile');
        this.router.navigate(["./profile"],{relativeTo:this.route});
        this.sharedService.headerOptionValue.next(this.menuProfile[0]);
        break;

      case 'myBooking' :
        console.log('myBooking');
        this.sharedService.headerOptionValue.next(this.menuProfile[0]);
        break;

      case 'karavanI':
        console.log('karavanal');
        this.router.navigate(['./'], {relativeTo: this.route});
        this.sharedService.headerOptionValue.next(this.menuProfile[0]);
        break;

      case 'logOut' :
        console.log('logOut');
        localStorage.removeItem('user');
        this.socialAuthService.signOut();
        this.sharedService.headerOptionValue.next(this.menuProfile[0]);
        break;

      default :
        console.log('default case !');
        this.sharedService.headerOptionValue.next(this.menuProfile[0]);
    };
    //this.selectedOption = {id: 4, name: this.user?.name_ar ?  this.user.name_ar : null};
  };



  //log Out Mobile
  logOutMobile() {
    localStorage.removeItem('user');
    this.sharedService.headerOptionValue.next(this.menuProfile[0]);
  }
  //open login popup
  handelAuthModule(): void {
    this.router.navigate(['auth']);

  }

  handelShowMobileNav() {
    this.showMobileNav = !this.showMobileNav;
  }

  //handel change value
  handelChangeValue(obj: any) {
    console.log(obj);
    const lang = obj.lang;
    localStorage.setItem('languageApp', lang);
    this.pathLang = obj.photo;
    this.translate.use(lang);
  }

}
