import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { TranslationService } from 'src/app/shared/translation.service';
import { PagesService } from '../../pages.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-filtter',
  templateUrl: './filtter.component.html',
  styleUrls: ['./filtter.component.scss']
})
export class FiltterComponent implements OnInit {

  constructor(public translate: TranslationService,
    private route: ActivatedRoute,
    private pagesService: PagesService,
    private spinner: NgxSpinnerService,
     private router: Router,
     public datepipe: DatePipe) {}

  //today date
  todayDate = new Date();

  //cities data
  cities: any[];
  selectedCity: any;

  //countries data
  countries: any[];
  selectedCountry: any;


  //beds data
  beds: any[];
  selectedBed: any;

  //sitest data
  sites: any[];
  selectedSite: any;

  //object search karavan
  searchOject: any = {};

  //response data
  data:any;

  //minimum date
  minDateValue:any;


  ngOnInit(): void {
    console.log("today date",this.todayDate);

    //get all countries
    this.pagesService.getCountry().subscribe(
      (response: any) => {
        console.log('country => ', response);
        this.countries = response.data;
      },
      (error) => {
        console.log(error);
      }
    );

    this.beds = [
      {id: '1', name: 1},
      {id: '2', name: 2},
      {id: '3', name: 3},
      {id: '4', name: 4},
      {id: '5', name: 5}
    ];

    this.sites = [
      {id: '1', name: 1},
      {id: '2', name: 2},
      {id: '3', name: 3},
      {id: '4', name: 4},
      {id: '5', name: 5}
    ];

  }

  //on change country
  onChangeCountry(){
    //this.searchOject.CountryId = this.selectedCountry.id;
    this.onGitCities();
  }

  //on change City
  onChangeCity() {
    //this.searchOject.cityId = this.selectedCity.id;
  }

  //get cities
  onGitCities() {
    this.pagesService.getCity(this.searchOject.CountryId.id).subscribe(
      (response: any) => {
        this.cities = response.data;
      },
      (error) => {
        console.log(error);
      }
    )
  };

  //changeLeftDate
  changeLeftDate() {
    if(this.searchOject.from){
      this.minDateValue =this.searchOject.from;
    }
  }

  onSearchHandel() {

    if(this.searchOject.from) {
      this.searchOject.from = this.datepipe.transform(this.searchOject.from, 'yyyy-MM-dd') || null;
    }

    if(this.searchOject.to) {
      this.searchOject.to = this.datepipe.transform(this.searchOject.to, 'yyyy-MM-dd') || null;
    }

    if(this.searchOject.CountryId) {
      this.searchOject.CountryId = this.searchOject.CountryId.id;
    }

    if(this.searchOject.cityId) {
      this.searchOject.cityId = this.searchOject.cityId.id;
    }

    if(this.searchOject.seatNo) {
      this.searchOject.seatNo = +this.searchOject.seatNo.id;
    }

    if(this.searchOject.bedsNo) {
      this.searchOject.bedsNo = +this.searchOject.bedsNo.id;
    }
    
    this.router.navigate(['../search'], {relativeTo: this.route, queryParams: {data: JSON.stringify(this.searchOject) , pageNo:1}});
  }


}
