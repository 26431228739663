import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PagesService } from '../../pages.service';
import { SharedService } from 'src/app/shared/shared.service';
import { TranslationService } from 'src/app/shared/translation.service';



@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  //user data
  userData:any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private pagesService: PagesService,
    public sharedService: SharedService,
    public translate: TranslationService,
  ) { }

  ngOnInit(): void {
     // get user data
     this.pagesService.getuserData().subscribe(
      (response: any) => {
        console.log('user data => ', response);
        this.userData = response.data;
      },
      (error:any) => {
        console.log(error);
      }
    );
  }

  // logout
  handleNav=(data:any)=>{
    switch(data){
      // function to change password
      case 'changePassword' :
      this.router.navigate(['./auth/forget']);
      break;
       // function for logout button in side menu
      case 'logOut' :
      localStorage.removeItem('user');
      this.router.navigate(['../home'],{relativeTo: this.route});
      break;
      default: break;
    }
  }

  changePassword=()=>{

  }



  logOut=()=>{

  }

  changeRoute(routeType: string) {
    switch(routeType) {
      case 'profile' :
        this.router.navigate(['../../profile'], {relativeTo: this.route})
        break;

      case 'favourite' :
          this.router.navigate(['../favourite'], {relativeTo: this.route});
          break;

      case 'yourCars' :
        this.router.navigate(['../yourCars'], {relativeTo: this.route});
        break;
    }
  }
}
