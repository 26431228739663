import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import {DropdownModule} from 'primeng/dropdown';
import {CalendarModule} from 'primeng/calendar';
import {CarouselModule} from 'primeng/carousel';


import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { HeaderComponent } from '../components/header/header.component';
import { FooterComponent } from '../components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { FiltterComponent } from './components/filtter/filtter.component';
import {TabViewModule} from 'primeng/tabview';
import { KaravanComponent } from './components/karavan/karavan.component';
import { PagesService } from './pages.service';
import {OurClientsComponent} from './home/our-clients/our-clients.component'
import { RatingModule } from 'ng-starrating';
import { ClipboardModule } from 'ngx-clipboard';
import {PaginatorModule} from 'primeng/paginator';
////import {DialogModule} from 'primeng/dialog';

import { NewCaravanComponent } from "./components/new-caravan/new-caravan.component";

import { NgxSpinnerModule } from "ngx-spinner";
import { AgmCoreModule } from '@agm/core';
import { ProductComponent } from './components/product/product.component';


@NgModule({
  declarations: [
      HeaderComponent,
      FooterComponent,
      FiltterComponent,
      KaravanComponent,
      SideMenuComponent,
      OurClientsComponent,
      NewCaravanComponent,
      ProductComponent
  ],
  imports: [
    CommonModule,
    DropdownModule,
    CalendarModule,
    RouterModule,
    TabViewModule,
    FormsModule,
    ReactiveFormsModule,
    RatingModule,
    CarouselModule,
    RatingModule,
    ClipboardModule,
    NgxSpinnerModule,
    PaginatorModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyByQ4RUtcrnc4zWeq4XgQ7VuaSgFIXSUuk'
    })
  ],
  exports: [
    DropdownModule,
    CalendarModule,
    TabViewModule,
    FiltterComponent,
    FormsModule,
    ReactiveFormsModule,
    HeaderComponent,
    FooterComponent,
    KaravanComponent,
    RatingModule,
    CarouselModule,
    SideMenuComponent,
    OurClientsComponent,
    RatingModule,
    ClipboardModule,
    NewCaravanComponent,
    NgxSpinnerModule,
    AgmCoreModule,
    ProductComponent,
    PaginatorModule
  ],
  providers: [
    PagesService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PagesSharedModule { }
