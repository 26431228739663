import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from 'src/app/shared/shared.service';
import { TranslationService } from 'src/app/shared/translation.service';
import { PagesService } from '../../pages.service';

@Component({
  selector: 'app-place-detail',
  templateUrl: './place-detail.component.html',
  styleUrls: ['./place-detail.component.scss']
})
export class PlaceDetailComponent implements OnInit {

  constructor(private pagesService: PagesService,
    public sharedService: SharedService,
    private spinner: NgxSpinnerService,
    public translate: TranslationService,
     private route: ActivatedRoute) { }

  placeId: number;
  placeData: any;
  singleImage: string;
  navUrl: string;

imagesList: string[];
  ngOnInit(): void {
    this.spinner.show();
    this.route.params.subscribe(
      (params: Params) => {
        this.placeId = params['placeId'];
      }
    );

    this.pagesService.getPlaceId(this.placeId).subscribe(
      (response: any) => {
        console.log(response);
        this.placeData = response.data.place;
        this.singleImage = response.data.images[0].imagePath;
        this.imagesList = response.data.images.map((element: any) => {
          return element.imagePath;
        });
        this.spinner.hide();
      }
    )
  }

  handelClickImg(img: string) {
    console.log(img)
    this.singleImage = img;
  }

    //on copy link url
    copyLink() {
      this.navUrl = window.location.href;
      this.sharedService.stringErrorMessage.next('تم نسخ الرابط');
      this.sharedService.errorShowMessage.next(true);
      this.sharedService.hideErrorMessage();
    }


    //handel heart click 
    handelHeart() {
       const data = {
        id: this.placeData.id,
        isFavorite: !this.placeData.isFavorite
      };
  
      this.pagesService.setPlaceFavourite(data).subscribe(
        (response: any) => {
          console.log(response);
          this.placeData.isFavorite = !this.placeData.isFavorite;
        },
        (error) => {
          console.log(error);
          if(error.status == 401) {
            this.placeData.isFavorite = this.placeData.isFavorite;
            this.sharedService.stringErrorMessage.next('قم بتسجيل الدخول اولاً');
            this.sharedService.errorShowMessage.next(true);
            this.sharedService.hideErrorMessage();
          }
          
        }
      );

    }

}
