<div [ngClass]="{'detail-place': translate.is_arabic, 'detail-placeEn': !translate.is_arabic}">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="detail-place">
                    <div class="header">
                        <h1>{{translate.is_arabic ? 'تفاصيل المكان' : 'Place Detail'}}</h1>
                    </div>
                    <div class="content">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="img-container">
                                    <div class="btns-div">
                                        <button (click)="handelHeart()" class="btttn heartBtn" *ngIf="!placeData?.isFavorite">
                                        </button>
                                        <button (click)="handelHeart()" class="btttn disheartBtn" *ngIf="placeData?.isFavorite">
                                        </button>
                                        <button class="btttn shareBtn" (click)="copyLink()" ngxClipboard [cbContent]="navUrl">
                                        </button>
                                    </div>

                                <img src="{{sharedService.ipRootImg + singleImage}}" alt="" loading="lazy" />
                                  <div class="img-sm-container">
                                    <img *ngFor="let img of imagesList"
                                          src="{{sharedService.ipRootImg + img}}"
                                          [ngClass]="{'activeImg': img == singleImage}"
                                          alt="" title=""
                                          loading="lazy"
                                          (click)="handelClickImg(img)"/>
                                  </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <h1>{{placeData ? placeData[translate.data.requestdData.genName] : 'N/A'}}</h1>
                                <p style="line-break: anywhere;" [innerHTML]="placeData ? placeData[translate.data.requestdData.genDescription] : 'N/A'"></p>
                                <span style="font-size: 12px;"><img style="height: 20px; margin-left: 10px;"
                                    src="../../../../assets/Content/location-pin/location-pin.png"/>
                                    {{placeData ? placeData[translate.data.requestdData.genCountry] : 'N/A'}}
                                    / {{placeData ? placeData[translate.data.requestdData.genCity] : 'N/A'}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
