import { Component, Input, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/shared/translation.service';
import {ActivatedRoute, Router } from '@angular/router';
import { PagesService } from '../../pages.service';
import { SharedService } from 'src/app/shared/shared.service';
import { ToastrService } from 'ngx-toastr';




@Component({
  selector: 'app-karavan',
  templateUrl: './karavan.component.html',
  styleUrls: ['./karavan.component.scss']
})
export class KaravanComponent implements OnInit {

  constructor(private router: Router ,
    public translate: TranslationService,
    private pagesService: PagesService,
    private route: ActivatedRoute,
    public sharedService: SharedService
    ) {}

  val1: number = 5;
  val2: number = 2;
  val3: number = 3;

  navUrl: string;

  karavans: any[] = [];
  karavansLength:any;
  data:any;

  ngOnInit ():void {
    this.pagesService.karavans.subscribe(
      (data: any) => {
        this.karavans = data?.resCarvans ? data.resCarvans : [];
        this.karavansLength = data?.carvanCount ? data?.carvanCount : 0;
        console.log('===================>', this.karavans);
        console.log('===================>', data);
      }
    );
  }

  onhandle=(id:any)=>{
    this.router.navigate(['../caravanDetails',id], {relativeTo: this.route});
  }

  //on copy link url
  copyLink() {
    this.navUrl = window.location.href;
    this.sharedService.stringErrorMessage.next('تم نسخ الرابط');
    this.sharedService.errorShowMessage.next(true);
    this.sharedService.hideErrorMessage();
  }

  //handel heart click
  handelHeart(item: any) {
    console.log('karavan => ', item);
    console.log(item.isFavorite);
    const data = {
      id: item.id,
      isFavorite: !item.isFavorite
    };

    this.pagesService.setFavourite(data).subscribe(
      (response: any) => {
        console.log(response);
        item.isFavorite = !item.isFavorite;
      },
      (error) => {
        console.log(error);
        if(error.status == 401) {
          item.isFavorite = item.isFavorite;
          this.sharedService.stringErrorMessage.next('قم بتسجيل الدخول اولاً');
          this.sharedService.errorShowMessage.next(true);
          this.sharedService.hideErrorMessage();
        }
      }
    )
  }

  paginate(event:any){
    console.log("page",event.page +1);
    let page = event.page +1;
    this.router.navigate(['../search'], {relativeTo: this.route, queryParams: {data: JSON.stringify({}) , pageNo:page}})
  }

}
