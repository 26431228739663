<nav class="navbar navbar-expand-lg navbar-light header sticky-top">
    <div class="container p-md-0 px-3">
        <a class="navbar-brand logo" href="#">
            <img src="../../../assets/header/Layer 1.png"
                title="logo"
                alt="logo"/>
        </a>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav"       aria-expanded="false" aria-label="Toggle navigation" (click)="handelShowMobileNav()">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="mobile-list d-block d-lg-none" *ngIf="showMobileNav">
            <ul class="navbar-nav menu">
                <li data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav"  aria-expanded="false" aria-label="Toggle navigation" (click)="handelShowMobileNav()">
                      <a routerLink="./home"
                          routerLinkActive="active"
                          [routerLinkActiveOptions]="{exact: true}">{{translate.data.HomePage.navigations.home}}
                      </a>
                </li>
                <!--   <li>
                      <a [routerLink]="['./search']" [queryParams]="{data: paramsData}" routerLinkActive="active">
                          {{translate.data.HomePage.navigations.leasing}}
                      </a>
                  </li> -->
                  <li data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav"  aria-expanded="false" aria-label="Toggle navigation" (click)="handelShowMobileNav()">
                      <a routerLink="./suggested-palces" routerLinkActive="active">
                          {{translate.data.HomePage.navigations.suggestPlaces}}
                      </a>
                  </li>
                  <li data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" *ngIf="authService.isAuth()" (click)="handelShowMobileNav()">
                      <a routerLink="./shareYourCar" routerLinkActive="active">
                          {{translate.data.HomePage.navigations.shareKaravan}}
                      </a>
                  </li>
                 <!--  <li>
                    <a routerLink="./store" routerLinkActive="active">
                        {{translate.data.HomePage.navigations.store}}
                    </a>
                  </li> -->
                  <li data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" (click)="handelShowMobileNav()">
                      <a routerLink="./about" routerLinkActive="active">
                          {{translate.data.HomePage.navigations.about}}
                      </a>
                  </li>
                  <li data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav"  aria-expanded="false" aria-label="Toggle navigation"  *ngIf="authService.isAuth()" (click)="handelShowMobileNav()">
                    <a routerLink="./profile" routerLinkActive="active">
                        الصفحه الشخصيه
                    </a>
                  </li>
                  <li data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" *ngIf="authService.isAuth()" (click)="handelShowMobileNav()">
                    <a routerLink="./profile/reservation" routerLinkActive="active">
                         حجوزاتي
                    </a>
                  </li>
                  <li data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" (click)="handelShowMobileNav()">
                    <a [routerLink]="['./search']" [queryParams]="{data: paramsData , pageNo:1}" routerLinkActive="active">
                        {{translate.data.HomePage.navigations.leasing}}
                    </a>
                  </li>
                  <li data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" (click)="handelShowMobileNav()">
                    <a routerLink="./contact" routerLinkActive="active">
                        {{translate.data.HomePage.navigations.contactUs}}
                    </a>
                  </li>
                  <li data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav"  aria-expanded="false" aria-label="Toggle navigation" (click)="handelAuthModule()" *ngIf="!authService.isAuth()">
                    <a routerLinkActive="active">
                        تسجيل الدخول
                    </a>
                  </li>
                  <li data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav"       aria-expanded="false" aria-label="Toggle navigation" (click)="logOutMobile()" *ngIf="authService.isAuth()">
                    <a routerLinkActive="active">
                         تسجيل الخروج
                    </a>
                  </li>
                  <div class="dropdown" style="padding: 0 5px;margin: 0 15px;">
                    <button style="padding: 0;" class="btn btn-secondary dropdown-toggle langBtn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      <img [src]="pathLang" class="img"/>
                    </button>
                    <ul class="dropdown-menu ll" aria-labelledby="dropdownMenuButton1" style="background-color: transparent;border: 0;">
                      <li *ngFor="let img of langIms" (click)="handelChangeValue(img)" style="cursor: pointer; margin: 0 5px;">
                          <img src="{{img.photo}}" title="" alt="" class="img" style="width: 25px; height: 25px; margin-bottom: 5px;"/>
                      </li>
                    </ul>
                  </div>
              </ul>
        </div>

        <div class="collapse navbar-collapse d-none d-lg-block " id="navbarNav">
            <ul class="navbar-nav menu">
            <li>
                    <a routerLink="./home"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}">{{translate.data.HomePage.navigations.home}}
                    </a>
                </li>
                <li>
                    <a [routerLink]="['./search']" [queryParams]="{data: paramsData , pageNo:1}" routerLinkActive="active">
                        {{translate.data.HomePage.navigations.leasing}}
                    </a>
                </li>
                <li>
                    <a routerLink="./suggested-palces" routerLinkActive="active">
                        {{translate.data.HomePage.navigations.suggestPlaces}}
                    </a>
                </li>
                <li>
                    <a routerLink="./shareYourCar" routerLinkActive="active">
                        {{translate.data.HomePage.navigations.shareKaravan}}
                    </a>
                </li>
               <!--  <li>
                <a routerLink="./store" routerLinkActive="active">
                    {{translate.data.HomePage.navigations.store}}
                </a>
                </li> -->
                <li>
                    <a routerLink="./about" routerLinkActive="active">
                        {{translate.data.HomePage.navigations.about}}
                    </a>
                </li>
                <li>
                    <a routerLink="./contact" routerLinkActive="active">
                        {{translate.data.HomePage.navigations.contactUs}}
                    </a>
                </li>
            </ul>
            <div class="dropdown" style="padding: 0 5px;margin: 0 15px;">
                <button style="padding: 0;" class="btn btn-secondary dropdown-toggle langBtn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <img [src]="pathLang" class="img"/>
                </button>
                <ul class="dropdown-menu ll" aria-labelledby="dropdownMenuButton1" style="background-color: transparent;border: 0;">
                  <li *ngFor="let img of langIms" (click)="handelChangeValue(img)" style="cursor: pointer; margin: 0 5px;">
                      <img src="{{img.photo}}" title="" alt="" class="img" style="width: 25px; height: 25px; margin-bottom: 5px;"/>
                  </li>
                </ul>
              </div>
         <!-- <select class="lang-select"
                    [(ngModel)]="language"
                   (change)="handelChangeValue($event)">
                <option value="ar" style="background: url(../../../assets/1024px-Flag_of_Saudi_Arabia.svg.png);"></option>
                <option value="en">en</option>
        </select> -->
        </div>
        <div class="navbar-brand auth-buttons d-none d-xl-block ">
            <span class="img-profile" *ngIf="authService.isAuth()"></span>
            <!-- <button class="new-member">{{translate.data.HomePage.newMember}}</button> -->
            <button class="login" (click)="handelAuthModule()" *ngIf="!authService.isAuth()">{{translate.data.HomePage.login}}</button>
            <p-dropdown
                *ngIf="authService.isAuth()"
                [options]="menuProfile"
                [(ngModel)]="selectedOption"
                optionLabel="name"
                (onChange)="onChange()"
                styleClass="countrySelect">
                <ng-template pTemplate="selectedItem">
                    <div class="option-item option-item-value"  *ngIf="selectedOption">
                        <div>{{selectedOption.name}}</div>
                    </div>
                </ng-template>
                <ng-template let-option pTemplate="item">
                    <div class="option-item">
                        <div>{{option.name}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
    </div>
</nav>
