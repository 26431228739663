import { Component, OnInit } from '@angular/core';
import { PagesService } from '../../pages.service';
import { SharedService } from 'src/app/shared/shared.service';
import { TranslationService } from 'src/app/shared/translation.service';


@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  products:any;

  constructor(
    private pagesService: PagesService,
    public sharedService: SharedService,
    public translate: TranslationService,
  ) { }

  ngOnInit(): void {
    this.pagesService.product.subscribe(
      (data: any) => {
        this.products = data? data : [];
        console.log('===================>', this.products);
      }
    );
  }

}
