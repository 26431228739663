import { CaravanService } from '../../components/caravan.service';
import {
  CreateCaravan,
  CaravanType,
  Features,
  Roles,
  CancelTypes,
  Cities,
  Countries,
  Conditon,
} from '../../components/caravan';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/shared/shared.service';
//declare var $: any;
import * as $ from "jquery"
@Component({
  selector: 'app-new-caravan',
  templateUrl: './new-caravan.component.html',
  styleUrls: ['./new-caravan.component.scss'],
})
export class NewCaravanComponent implements OnInit {
  CreateCaravan: CreateCaravan;
  EditCaravan = {} as CreateCaravan;
  FeaturesData: Features[];
  RolesData: Roles[];
  CaravanType: CaravanType[];
  Feature: Features[];
  Roles: Roles[];
  CancelType: CancelTypes[];
  Cities: Cities[];
  checkedIDs: Array<any> = [];
  checkedUpdated: Array<any> = [];
  checkedFeature: Array<any> = [];
  rolesUpdated: Array<any> = [];
  imageSrc: Array<any> = [];
  imageOb: any = {};
  oldImages: Array<any> = [];
  lat: any = 24.774265;
  lng: any = 46.738586;
  zoom = 16;
  locationChosen = false;
  rolecheckedIDs: Array<any> = [];
  checkedRoles: Array<any> = [];
  addNewTypeForm: any;
  imageArr: Array<any> = [];
  Countries: Countries[];
  Conditon: Conditon[];
  Internet: Conditon[];
  countId: number;
  editId: number;
  isAddMode: boolean = true;
  ssnImages: Array<any> = [];
  ssnSrc: Array<any> = [];
  ssnOb: any = {};
  oldSSN: Array<any> = [];
  lincenceImages: Array<any> = [];
  linceseSrc: Array<any> = [];
  licenceOb: any = {};
  oldLicense: Array<any> = [];
  defaultImgPath: string = '../../assets/Images/upload.svg';
  constructor(
    private caravanService: CaravanService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public sharedService: SharedService,
    private router: Router,
    private toastr: ToastrService
  ) {}
  @ViewChild('doneModal') doneModal: ElementRef;
  // UPLOAD IMAGES ==================
  handleInputChange(e: any) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e: any) {
    let reader = e.target;
    // ==============
    this.imageArr.push(reader.result); //for html
    this.imageOb = { id: 0, imagePath: reader.result };
    this.imageOb.imagePath = this.imageOb.imagePath.split(',');
    this.imageOb.imagePath = this.imageOb.imagePath[1];
    this.imageSrc.push(this.imageOb);
    this.oldImages = this.imageSrc;
    console.log(this.imageSrc);
  }

  removeSelectedFile(i: any) {
    console.log(this.imageArr);
    this.imageArr.splice(i, 1);
    this.oldImages.splice(i, 1);
    console.log(this.oldImages);
    /* var index = this.imageArr.indexOf(i, 0);
    var index2 = this.oldImages.indexOf(i, 0); */
    //var ind = this.EditCaravan.images.indexOf(i, 0);
 /*    if (index > -1) {
      this.imageArr.splice(index, 1);
    }
    if (index2 > -1) {
      this.oldImages.splice(index2, 1);
    }
    if (ind > -1) {
      this.EditCaravan.images.splice(ind, 1);
    } */
  }

  //Papers Images ====================
  handleSSnChange(e: any) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleSSN.bind(this);
    reader.readAsDataURL(file);
  }
  _handleSSN(e: any) {
    let reader = e.target;
    // ===============
    this.ssnImages.push(reader.result); //for html
    this.ssnOb = { id: 0, imagePath: reader.result };
    this.ssnOb.imagePath = this.ssnOb.imagePath.split(',');
    this.ssnOb.imagePath = this.ssnOb.imagePath[1];
    this.oldSSN.push(this.ssnOb);
    this.ssnSrc = this.oldSSN;
    console.log(this.oldSSN);
  }
  removeSNNFile(i: any) {
    this.ssnImages.splice(i, 1);
    this.ssnSrc.splice(i, 1);
    console.log(this.ssnImages);
/*     var index = this.ssnImages.indexOf(i, 0);
    var index2 = this.ssnSrc.indexOf(i, 0);
    var ind = this.EditCaravan.ssnImages.indexOf(i, 0);
    if (index > -1) {
      this.ssnImages.splice(index, 1);
    }
    if (index2 > -1) {
      this.ssnSrc.splice(index2, 1);
    }
    if (ind > -1) {
      this.EditCaravan.ssnImages.splice(ind, 1);
    } */
  }
  // =================
  handleLicenseChange(e: any) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleLicense.bind(this);
    reader.readAsDataURL(file);
  }
  _handleLicense(e: any) {
    let reader = e.target;
    // ===============
    this.lincenceImages.push(reader.result); //for html
    this.licenceOb = { id: 0, imagePath: reader.result };
    this.licenceOb.imagePath = this.licenceOb.imagePath.split(',');
    this.licenceOb.imagePath = this.licenceOb.imagePath[1];
    this.oldLicense.push(this.licenceOb);
    this.linceseSrc = this.oldLicense;
    console.log(this.oldLicense);
  }
  removeLicenseFile(i: any) {
    this.lincenceImages.splice(i, 1);
    this.linceseSrc.splice(i, 1);
    console.log(this.linceseSrc);
  /*   var index = this.lincenceImages.indexOf(i, 0);
    var index2 = this.linceseSrc.indexOf(i, 0);
    var ind = this.EditCaravan.licenseImages.indexOf(i, 0);
    if (index > -1) {
      this.lincenceImages.splice(index, 1);
    }
    if (index2 > -1) {
      this.linceseSrc.splice(index2, 1);
    }
    if (ind > -1) {
      this.EditCaravan.licenseImages.splice(ind, 1);
    } */
  }
  // =================
  // GET DATA =======================
  getCaravanType() {
    this.caravanService.getCaravanType().subscribe((data) => {
      this.CaravanType = data;
    });
  }
  getConditionType() {
    this.caravanService.getConditionType().subscribe((data) => {
      this.Conditon = data;
    });
  }
  getInternet() {
    this.caravanService.getInternet().subscribe((data) => {
      this.Internet = data;
    });
  }
  getFeatures() {
    this.caravanService.getFeatures().subscribe((data) => {
      this.Feature = data;
    });
  }
  getRoles() {
    this.caravanService.getRoles().subscribe((data) => {
      this.Roles = data;
    });
  }
  getCancleTypes() {
    this.caravanService.getCancleTypes().subscribe((data) => {
      this.CancelType = data;
    });
  }
  getcountryId(event: any) {
    this.spinner.show('loader');
    this.countId = +event.target.value;
    this.getCities(this.countId);
  }
  getCities(id: number) {
    this.caravanService.getCitiesId(id).subscribe((data) => {
      this.Cities = data;
      this.spinner.hide('loader');
    });
  }
  getCountries() {
    this.caravanService.getCountries().subscribe((data) => {
      this.Countries = data;
    });
  }
  // Google MAP =====================
 onChooseLocation(event: any) {
    console.log(event);
    this.lat = event.coords.lat;
    this.lng = event.coords.lng;
    this.locationChosen = true;
  }
  markerDragEnd(event: any) {
    console.log(event);
  }
  getUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
      });
    }
  }
  // Features Filter ================
  fetchCheckedIDs(c: any) {
    if (this.isAddMode) {
      if (c.isChecked) {
        this.checkedIDs.push(c.id);
      } else {
        var index = this.checkedIDs.findIndex((id) => id == c.id);
        this.checkedIDs.splice(index, 1);
      }
    } else {
      var index = this.checkedFeature.findIndex((id) => id == c.id);
      var index2 = this.checkedUpdated.findIndex((id) => id == c.id);
      if (index != -1) {
        c.isChecked = !c.isChecked;
        if (c.isChecked) {
          this.checkedFeature.push(c.id);
        } else {
          this.checkedFeature.splice(index, 1);
        }
        console.log(this.checkedFeature);
      } else {
        c.isChecked = !c.isChecked;
        if (c.isChecked) {
          this.checkedUpdated.push(c.id);
        } else {
          this.checkedUpdated.splice(index2, 1);
        }
        console.log(this.checkedUpdated);
      }
    }
    console.log(this.checkedIDs);
  }
  roleCheckedIDs(r: any) {
    if (this.isAddMode) {
      if (r.isChecked) {
        this.rolecheckedIDs.push(r.id);
      } else {
        var index = this.rolecheckedIDs.findIndex((id) => id == r.id);
        this.rolecheckedIDs.splice(index, 1);
      }
    } else {
      var index = this.checkedRoles.findIndex((id) => id == r.id);
      var index2 = this.rolesUpdated.findIndex((id) => id == r.id);
      if (index != -1) {
        r.isChecked = !r.isChecked;
        if (r.isChecked) {
          this.checkedRoles.push(r.id);
        } else {
          this.checkedRoles.splice(index, 1);
        }
      } else {
        r.isChecked = !r.isChecked;
        if (r.isChecked) {
          this.rolesUpdated.push(r.id);
        } else {
          this.rolesUpdated.splice(index2, 1);
        }
      }
    }
    console.log(this.rolecheckedIDs);
  }
  // Edit Data ====================
  getEditData() {
    this.isAddMode = !this.editId;
    if (!this.isAddMode) {
      this.caravanService.getCaravanId(this.editId).subscribe((data) => {
        this.EditCaravan = data;
        this.FeaturesData = data.features;
        this.RolesData = data.roles;
        for (let i = 0; i < this.FeaturesData.length; i++) {
          for (let j = 0; j < this.FeaturesData[i].featureItems.length; j++) {
            if (this.FeaturesData[i].featureItems[j].isChecked) {
              this.checkedFeature.push(this.FeaturesData[i].featureItems[j].id);
            }
          }
        }
        for (let i = 0; i < this.RolesData.length; i++) {
          for (let j = 0; j < this.RolesData[i].roleItems.length; j++) {
            if (this.RolesData[i].roleItems[j].isChecked) {
              this.checkedRoles.push(this.RolesData[i].roleItems[j].id);
            }
          }
        }
      });
    }
  }
  // SUBMIT FORM ====================
  onSubmit(detailsForm: any) {
    //add mode 
    if(this.isAddMode) {
      detailsForm.features = this.checkedIDs;
      detailsForm.roles = this.rolecheckedIDs;
      detailsForm.images = this.oldImages;
      detailsForm.ssnImages = this.ssnSrc;
      detailsForm.licenseImages = this.linceseSrc;
      console.log(detailsForm.features);
      console.log(detailsForm.roles);
      console.log(detailsForm.images);
      console.log(detailsForm.ssnImages);
      console.log(detailsForm.licenseImages);

    } else {
      //edit mode 
      this.checkedIDs = this.checkedFeature.concat(this.checkedUpdated);
      this.rolecheckedIDs = this.rolesUpdated.concat(this.checkedRoles);
      this.oldImages = this.imageSrc.concat(this.EditCaravan.images);
      this.ssnSrc = this.oldSSN.concat(this.EditCaravan.ssnImages);
      this.linceseSrc = this.oldLicense.concat(this.EditCaravan.licenseImages);
    }
  
    detailsForm.lat = this.lat;
    detailsForm.long = this.lng;

    // ==============
    if (this.isAddMode) {
      this.spinner.show('loader');
      this.oldImages = this.imageSrc;
      this.ssnSrc = this.oldSSN;
      this.linceseSrc = this.oldLicense;
      this.caravanService.createCaravan(detailsForm).subscribe(
        (res) => {
          this.spinner.hide('loader');
          this.doneModal.nativeElement.click();
          console.log('Data Post Done');
          this.router.navigate(['../profile/yourCars'], {relativeTo: this.route});
        },
        (error) => {
          this.spinner.hide('loader');
          this.toastr.error('فشلت العملية');
        }
      );
    } else {
      this.spinner.show('loader');
      this.caravanService.editCaravan(this.editId, detailsForm).subscribe(
        (data) => {
          console.log('editedd');
          this.spinner.hide('loader');
          this.doneModal.nativeElement.click();
        },
        (error) => {
          this.spinner.hide('loader');
          this.toastr.error('فشلت العملية');
        }
      );
    }
    console.log(this.oldImages, '1111', this.ssnSrc, '222', this.linceseSrc);
  }

  toNext() {
    $('.nav-link.active').addClass('visit').removeClass('active').next().addClass('active');
    $('.tab-pane.active').removeClass('show active').next().addClass('show active');
  }

  toPrev() {
    $('.nav-link.active').removeClass('active').prev().addClass('active').removeClass('visit');
    $('.tab-pane.active').removeClass('show active').prev().addClass('show active');
  }
  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.editId = +params.get('id')!;
    });
    this.isAddMode = !this.editId;
    this.getCaravanType();
    this.getConditionType();
    this.getInternet();
    this.getFeatures();
    this.getRoles();
    this.getCancleTypes();
    this.getCountries();
    this.getEditData();
    this.getUserLocation();
  }
}
