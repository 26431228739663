
  <div class="row">
    <div class="col-12 ">
      <div class="sideMenu">
        <div class="header col-12 d-none d-lg-flex">
          <img src="{{sharedService.ipRootImg + userData?.image}}" alt="profilePic">
        </div>
        <div [ngClass]="{'body': translate.is_arabic, 'bodyEng': !translate.is_arabic}">
          <div>
            <ul>
              <li>
                <a routerLink="./"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">{{translate.data.profile.account}}</a>
            </li>
            <li>
                <a routerLink="./favourite" routerLinkActive="active">{{translate.data.favourite.favourite}}</a>
            </li>
            <li>
                <a routerLink="./yourCars" routerLinkActive="active">{{translate.data.sideMenu.myCaravans}}</a>
            </li>
           <!-- <li>
              <a routerLink="./places" routerLinkActive="active">{{translate.data.sideMenu.Finances}}</a>
            </li>
            <li>
              <a routerLink="./places" routerLinkActive="active">{{translate.data.sideMenu.mywallet}}</a>
            </li> -->
            <li>
                <a routerLink="./reservation" routerLinkActive="active">{{translate.data.reservationProfile.myReservation}}</a>
            </li>
            <li>
                <a routerLink="./changePassword" routerLinkActive="active">{{translate.data.sideMenu.changePassword}}</a>
            </li>
            <li>
                <a (click)="handleNav('logOut')">{{translate.data.sideMenu.logOut}}</a>
            </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

