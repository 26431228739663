import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from '../shared/shared.service';
import { TranslationService } from './translation.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {

  constructor(public sharedSer: SharedService,
    private spinner: NgxSpinnerService,
     public translate: TranslationService) {

  }

  ngOnInit(): void {
  }

}
