<div class="sticky-bottom" [ngClass]="{'footer': translate.is_arabic, 'footerEn': !translate.is_arabic}">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3 d-none d-md-block">
                <div class="row">
                    <h2>{{translate.data.footer.quickLinks.quickLi}}</h2>
                    <div class="col-6">
                        <div class="quick-links">
                            <ul>
                                <li>
                                    <a routerLink="./">
                                        {{translate.data.footer.quickLinks.home}}
                                    </a>
                                </li>
                                <li>
                                    <a routerLink="./about">
                                        {{translate.data.footer.quickLinks.whoUs}}
                                    </a>
                                </li>
                                <li>
                                    <a  [routerLink]="['./search']" [queryParams]="{data: paramsData}">
                                        {{translate.data.footer.quickLinks.bookingKaravan}}
                                    </a>
                                </li>
                               <!--  <li>
                                    {{translate.data.footer.quickLinks.store}}
                                </li> -->
                                <li>
                                    <a routerLink="./suggested-palces">
                                        {{translate.data.footer.quickLinks.suggestsPlaces}}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="quick-links">
                            <ul>
                                <li>
                                    <a routerLink="./policy">
                                        {{translate.data.footer.quickLinks.policy}}
                                    </a>
                                </li>
                                <li>
                                    <a routerLink="./privacyPolicy">
                                        {{translate.data.footer.quickLinks.privacy}}
                                    </a>
                                </li>
                                <li>
                                    <a routerLink="./contact">
                                        {{translate.data.footer.quickLinks.contactUs}}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-sm-6 col-lg-3 d-none d-md-block">
                <div class="karvan-owner">
                    <h2>{{translate.data.footer.karavanOwner}}</h2>
                    <a routerLink="./shareYourCar">{{translate.data.footer.shareKaravan}}</a>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3  d-none d-md-block">
                <div class="support">
                    <h2>{{translate.data.footer.support}}</h2>
                    <a routerLink="./questions">
                        {{translate.data.footer.moreQuestions}}
                    </a>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div [ngClass]="{'contact': translate.is_arabic, 'contact-en': !translate.is_arabic}">
                    <h2>{{translate.data.footer.subscribe}}</h2>
                    <div class="form-floating mb-3">
                        <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com">
                        <img src="../../../assets/Footer/Group 10434/Group 10434.png" alt="footer" title="footer"/>
                        <label for="floatingInput">{{translate.data.footer.email}}</label>
                    </div>
                </div>
                <div [ngClass]="{'master': translate.is_arabic, 'masterEn': !translate.is_arabic}">
                    <ul class="list-unstyled">
                        <li>
                            <img src="../../../assets/profile/payment/credit-cards_amex.svg" alt="" title="" />
                        </li>
                        <li>
                            <img src="../../../assets/profile/payment/credit-cards_mastercard.svg" alt="" title="" />
                        </li>
                        <li>
                            <img src="../../../assets/profile/payment/credit-cards_visa.svg" alt="" title="" />
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-md-12">
                <div class="social-media">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="copyRight">
                                <p>{{translate.data.footer.copyRight}}</p>
                                <span style="font-size: 10px">V.1.1.1</span>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div [ngClass]="{'socialLinks': translate.is_arabic, 'socialLinksEn': !translate.is_arabic}">
                                <ul class="list-unstyled">
                                    <li>
                                        <a href="{{ 'https://' + socialLinks?.facebookLink}}" target="_blank">
                                            <img src="../../../assets/Content/tw/Group 12.png" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="{{ 'https://' + socialLinks?.twitterLink}}" target="_blank">
                                            <img src="../../../assets/Content/fb/Group 11.png" />
                                        </a>
                                    </li>
                                    <li>
                                        <a class="spicial-style-icon" href="{{ 'https://' + socialLinks?.youtubeLink}}" target="_blank">
                                            <img  src="../../../assets/Content/yo/whatsapp.svg" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="{{ 'https://' + socialLinks?.instgramLink}}" target="_blank">
                                            <img src="../../../assets/Content/instgram.png" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="{{'https://' + socialLinks?.snapchatLink}}" target="_blank">
                                            <img src="../../../assets/Content/snabshat.png" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
